import React from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { ButtonSettings, ButtonFloatingSettings } from './ButtonSettings'
import { Button as OriginalButton } from 'react-components/atoms'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {
  label: 'Click me',
  url: '',
  openNewTab: false,
  variant: 'primary',
  colorVariant: '',
  size: 'md',
  align: '',
}

export const dynamicProps = []

export const Button = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { url, openNewTab, variant, colorVariant, size, align } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const { enabled } = useEditor(state => ({
      enabled: state.options.enabled,
    }))

    return (
      <div
        ref={ref => props.retRef(ref)}
        className={`block-button ${align ? `block-button--${align}` : ''} ${props.className}`}
      >
        {enabled ? (
          <OriginalButton
            variant={variant}
            colorVariant={colorVariant}
            label={children.props.children[0]}
            size={size}
          />
        ) : (
          <OriginalButton
            href={_(url)}
            external={openNewTab}
            newPage={openNewTab}
            variant={variant}
            colorVariant={colorVariant}
            label={children.props.children[0]}
            size={size}
          />
        )}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Button.craft = withCraft({
  name: 'Button',
  defaultProps: defaultProps,
  settings: ButtonSettings,
  floatingSettings: ButtonFloatingSettings,
})
