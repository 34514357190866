import React, { useEffect, useRef, useCallback, useContext } from 'react'
import { useNode, useEditor, ROOT_NODE } from '@craftjs/core'
import {
  ChevronDown,
  ChevronUp,
  Bin,
  Options,
  WireframeMode,
  PlusCopy,
} from 'react-pagebuilder/pb-components/icons'
import { cloneNodeTree, addNodeTree } from '../utils/node'
import {
  isDisabled,
  isClonable,
  isDeletable,
  isMoveable,
  isParentSelectable,
  deleteNodeTree,
} from '../utils/node'
import { update as updateDynamicDataBlock } from '../components/DynamicDataBlock'
import ReactDOM from 'react-dom'
import { GlobalContext } from 'react-pagebuilder/services/globalContext'

import { FloatingSettings } from './FloatingSettings'
import { Text } from 'react-pagebuilder/components/Text'

export const RenderNode = ({ render }) => {
  const { actions, query } = useEditor()

  const {
    id,
    isActive,
    isHover,
    dom,
    name,
    connectors: { drag },
    parent,
  } = useNode(node => ({
    isActive: node.events.selected,
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.name,
    parent: node.data.parent,
    type: node.data.type,
  }))

  const { floatingSettingsOpen, setFloatingSettingsOpen } = useContext(GlobalContext)

  useEffect(() => {
    if (!isActive) {
      setFloatingSettingsOpen(false)
    }
  }, [isActive])

  const currentRef = useRef()

  const deletable = isDeletable(id, query)
  const moveable = isMoveable(id, query)
  const clonable = isClonable(id, query)
  const parentSelectable = isParentSelectable(id)
  const disabled = isDisabled(id, query)

  const getPos = useCallback(
    dom => {
      const { top, left, bottom } = dom
        ? dom.getBoundingClientRect()
        : { top: 0, left: 0, bottom: 0 }
      return {
        top: top > 0 ? top : bottom,
        left: left,
        bottom: bottom,
      }
    },
    [dom]
  )

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef

    if (!currentDOM) return
    const { top, left } = getPos(dom)
    currentDOM.style.top = `${top}px`
    currentDOM.style.left = `${left}px`
  }, [dom])

  useEffect(() => {
    const dom = document.querySelector('.craftjs-renderer')
    if (dom) {
      dom.addEventListener('scroll', scroll)
    }

    return () => {
      const dom = document.querySelector('.craftjs-renderer')
      if (dom) {
        dom.removeEventListener('scroll', scroll)
      }
    }
  }, [scroll])

  return (
    <>
      {!disabled &&
        (isHover || isActive) &&
        ReactDOM.createPortal(
          <div
            ref={currentRef}
            className={`component-menu component-menu-${isActive ? 'selected' : 'hovered'}`}
            style={{
              left: `${getPos(dom).left}px`,
              top: `${getPos(dom).top}px`,
            }}
          >
            <span className="component-menu__name">{name}</span>
            {isActive && (
              <span className="component-menu__actions">
                {moveable && (
                  <a className="cursor-move" ref={drag}>
                    <WireframeMode />
                  </a>
                )}
                {parentSelectable && (
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      actions.selectNode(parent)
                    }}
                  >
                    <ChevronUp />
                  </a>
                )}
                {clonable && (
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      let idToClone = id
                      let parentToClone = parent

                      const parentType = query.node(parent).get().data.type
                      if (parentType === Text) {
                        idToClone = parent
                        parentToClone = query.node(parent).get().data.parent
                      }

                      const nodeTree = cloneNodeTree(idToClone, query)
                      const index = query
                        .node(parentToClone)
                        .get()
                        .data.nodes.indexOf(idToClone)
                      addNodeTree(nodeTree, parentToClone, actions, false, index + 1)
                    }}
                  >
                    <PlusCopy />
                  </a>
                )}
                {deletable && (
                  <a
                    className="cursor-pointer"
                    onClick={e => {
                      e.stopPropagation()
                      const parentType = query.node(parent).get().data.type
                      if (parentType === Text) {
                        deleteNodeTree(parent, actions)
                      } else {
                        deleteNodeTree(id, actions)
                      }
                    }}
                  >
                    <Bin />
                  </a>
                )}
                {name === 'DynamicDataBlock' && (
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      updateDynamicDataBlock(id, query, actions)
                    }}
                  >
                    <ChevronDown />
                  </a>
                )}
                {id !== ROOT_NODE && (
                  <>
                    <a
                      className="cursor-pointer"
                      onClick={() => {
                        setFloatingSettingsOpen(!floatingSettingsOpen)
                      }}
                    >
                      <Options />
                    </a>
                    <FloatingSettings
                      parentPos={{
                        left: getPos(dom).left,
                        top: getPos(dom).top,
                      }}
                    />
                  </>
                )}
              </span>
            )}
          </div>,
          document.body
        )}
      {render}
    </>
  )
}
