import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { adminApiAxiosInstance } from 'react-pagebuilder/services/api'

const alignOptions = [
  { label: 'Default', value: '' },
  { label: 'Sinistra', value: 'left' },
  { label: 'Centro', value: 'center' },
  { label: 'Destro', value: 'right' },
]

const typeOptions = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  // { label: 'Tertiary', value: 'tertiary' } - non ci sono varianti colori per il tertiary
]
const colorOptions = [
  { label: 'Blue', value: '' },
  { label: 'Orange', value: 'orange' },
]
const sizeOptions = [
  { label: 'default', value: 'md' },
  { label: 'small', value: 'sm' },
]

export const ButtonSettings = (props) => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  const [urlOpts, setUrlOpts] = useState()

  useEffect(() => {
    adminApiAxiosInstance.get('linkable_objects/?pagebuilder=true').then((res) => {
      setUrlOpts(
        res.data.reduce(
          (r, x) =>
            r.concat(
              x.linkable_objects.map((y) => ({
                label: y.alias,
                value: x.content_type.id + '|' + y.id,
              }))
            ),
          []
        )
      )
    })
  }, [])

  return (
    <>
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="variant"
          type="select"
          label="Type"
          options={typeOptions}
          dynamic={dynamicProps.includes('variant') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['variant']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="colorVariant"
          type="select"
          label="Color"
          options={colorOptions}
          dynamic={dynamicProps.includes('colorVariant') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['colorVariant']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="size"
          type="select"
          label="Size"
          options={sizeOptions}
          dynamic={dynamicProps.includes('size') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['size']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="align"
          type="select"
          label="Allineamento"
          options={alignOptions}
          dynamic={dynamicProps.includes('align') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['align']}
        />
      </ToolbarSection>
      {!props.floating && (
        <ToolbarSection title="Settings" initOpen>
          {urlOpts && (
            <ToolbarItem
              propKey="block"
              propSubKey="url"
              type="select"
              label="Page Link"
              options={urlOpts}
              nullable
              defaultValue={defaultProps['url']}
            />
          )}
          <ToolbarItem
            propKey="block"
            propSubKey="url"
            type="text"
            label="Ext Link"
            dynamic={dynamicProps.includes('url') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['url']}
          />
          <ToolbarItem
            propKey="block"
            propSubKey="openNewTab"
            type="switch"
            label="Open in new tab"
            dynamic={dynamicProps.includes('openNewTab') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['openNewTab']}
          />
        </ToolbarSection>
      )}
    </>
  )
}

export const ButtonFloatingSettings = () => React.createElement(ButtonSettings, { floating: true })
