import React from 'react'
import Tooltip from 'components/atomic/Tooltip'
import ReactTooltip from 'react-tooltip'
import { renderToString } from 'react-dom/server'

const PBTooltip = ({ label = '', id = '', pos = 'top', className = '', children = null }) => {
  return (
    <span data-for={id} data-tip={renderToString(<Tooltip value={label} />)}>
      {children}
      <ReactTooltip
        id={id}
        html
        type="light"
        place="bottom"
        effect="solid"
        className="react-tooltip react-tooltip--pagebuilder"
      />
    </span>
  )
}

export default PBTooltip
