import { useEditor, ROOT_NODE } from '@craftjs/core'
import { ToolbarSection } from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { deserializeNodeTree, addNodeTree } from 'react-pagebuilder/utils/node'
import { canDropBlock } from 'react-pagebuilder/utils/rules'
import { parseNodeTree } from 'react-pagebuilder/utils/block'
import { useEffect, useState, useContext } from 'react'
import { CustomBlocksContext } from 'react-pagebuilder/services/customBlocksContext'
import { conf } from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbox/conf'
import { GenericBlock } from 'react-pagebuilder/pb-components/icons'

export const Toolbox = () => {
  const {
    connectors: { create },
    query,
    actions,
    selectedNodeId,
  } = useEditor(state => ({
    selectedNodeId: state.events.selected,
  }))

  const { customBlocks: customBlocksObject } = useContext(CustomBlocksContext)

  const [prebuiltCustomBlocks, setPrebuiltCustomBlocks] = useState()

  useEffect(() => {
    if (customBlocksObject) {
      let prebuiltTmp = []
      customBlocksObject.forEach(block => {
        let newBlock = JSON.parse(JSON.stringify(block))
        const serializedNodeTree = JSON.parse(block.json_content)
        const nodeTree = deserializeNodeTree(serializedNodeTree, query)
        newBlock['nodeTree'] = nodeTree
        const element = parseNodeTree(nodeTree, nodeTree['rootNodeId'])
        newBlock['element'] = element
        if (block.prebuilt) {
          prebuiltTmp.push(newBlock)
        }
      })
      setPrebuiltCustomBlocks(prebuiltTmp)
    }
  }, [customBlocksObject])

  const addBlock = element => {
    const nodeTree = query.parseReactElement(element).toNodeTree()
    const currentNode = nodeTree['nodes'][nodeTree['rootNodeId']]
    let targetNode = null
    if (selectedNodeId) {
      targetNode = query.node(selectedNodeId).get()
    } else {
      targetNode = query.node(ROOT_NODE).get()
    }

    if (canDropBlock(currentNode, targetNode)) {
      addNodeTree(nodeTree, targetNode.id, actions)
    } else {
      console.log(`Can not drop ${currentNode.data.name} in ${targetNode.data.name}`)
    }
  }

  const [activeTab, setActiveTab] = useState('Blocks')

  const renderBlock = (element, name, icon) => {
    return (
      <div
        className="toolbox__tools__item"
        ref={ref => create(ref, element)}
        onClick={() => addBlock(element)}
        key={name}
      >
        {icon ? icon : <GenericBlock />}
        <span>{name}</span>
      </div>
    )
  }

  return (
    <div className="toolbox">
      {/* SOLO BLOCKS IN QUESTO CASO, NIENTE TAB LAYOUT
      <div className="toolbox__tabs">
        <div
          className={`toolbox__tab-item ${
            activeTab === 'Blocks' ? 'toolbox__tab-item--active' : ''
          }`}
          onClick={() => setActiveTab('Blocks')}
        >
          <span>Blocks</span>
        </div>
        <div
          className={`toolbox__tab-item ${
            activeTab === 'Layouts' ? 'toolbox__tab-item--active' : ''
          }`}
          onClick={() => setActiveTab('Layouts')}
        >
          <span>Layouts</span>
        </div>
      </div>*/}
      <div className="toolbox__tools">
        {activeTab === 'Blocks' && (
          <>
            {conf.map(conf => (
              <ToolbarSection title={conf.title} key={conf.title} open>
                <div className="toolbox__tools__wrapper">
                  {Object.keys(conf.blocks).map(key =>
                    renderBlock(
                      conf.blocks[key].block,
                      conf.blocks[key].name,
                      conf.blocks[key].icon
                    )
                  )}
                </div>
              </ToolbarSection>
            ))}
          </>
        )}
        {/* activeTab === 'Layouts' && (
          <ToolbarSection title="Prebuilt Layouts">
            {prebuiltCustomBlocks?.length ? (
              <div className="toolbox__custom-blocks">
                {prebuiltCustomBlocks.map((block) => {
                  return (
                    <div className="toolbox__custom-blocks__item" key={block.name}>
                      {renderBlock(block.element, block.name)}
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="empty-message">
                <p>No elements yet</p>
              </div>
            )}
          </ToolbarSection>
        )*/}
      </div>
    </div>
  )
}
