import React, { useContext, useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { GlobalContext } from 'react-pagebuilder/services/globalContext'
import { api } from 'utils'
import { STRUMENTI } from 'utils/endpoint'

export const StrumentoEmbeddedSettings = props => {
  const { customs } = useNode(node => ({
    customs: node.data.custom,
  }))

  const [strumentoIdOpts, setStrumentoIdOpts] = useState([])
  const { contentTypeId, objectId } = useContext(GlobalContext)

  useEffect(() => {
    fetchStrumenti()
  }, [])

  async function fetchStrumenti() {
    const { data } = await api.get(`${STRUMENTI}get_by_content_object/`, {
      params: { content_type_id: contentTypeId, object_id: objectId },
    })
    setStrumentoIdOpts(data.map(s => ({ label: s.titolo, value: s.id })))
  }

  return (
    <ToolbarSection title="Settings" initOpen>
      <ToolbarItem
        propKey="block"
        propSubKey="strumentoId"
        type="select"
        label="Strumento"
        options={strumentoIdOpts}
        dynamic={dynamicProps.includes('strumentoId') && customs.dynamicData}
        dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
        defaultValue={defaultProps['strumentoId']}
      />
    </ToolbarSection>
  )
}
