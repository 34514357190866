import React from 'react'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

export const ColSettings = () => {
  const mdWidthOptions = [
    { value: '', label: 'Auto' },
    { value: '12', label: '100%' },
    { value: '9', label: '75%' },
    { value: '8', label: '66%' },
    { value: '6', label: '50%' },
    { value: '4', label: '33%' },
    { value: '3', label: '25%' },
  ]

  const smWidthOptions = [
    { value: '', label: 'Auto' },
    { value: '12', label: '100%' },
    { value: '9', label: '75%' },
    { value: '8', label: '66%' },
    { value: '6', label: '50%' },
    { value: '4', label: '33%' },
    { value: '3', label: '25%' },
  ]

  const xsWidthOptions = [
    { value: '', label: 'Auto' },
    { value: '12', label: '100%' },
    { value: '9', label: '75%' },
    { value: '8', label: '66%' },
    { value: '6', label: '50%' },
    { value: '4', label: '33%' },
    { value: '3', label: '25%' },
  ]

  const alignOptions = [
    { value: '', label: 'Default' },
    { value: 'left', label: 'Sinista' },
    { value: 'center', label: 'Centro' },
    { value: 'right', label: 'Destra' },
  ]

  const vAlignOptions = [
    { value: '', label: 'Default' },
    { value: 'top', label: 'Alto' },
    { value: 'center', label: 'Centro' },
    { value: 'bottom', label: 'Basso' },
  ]

  return (
    <ToolbarSection title="Settings" initOpen>
      <ToolbarItem
        propKey="block"
        propSubKey="mdWidth"
        type="select"
        options={mdWidthOptions}
        label="Desktop Width"
      />
      <ToolbarItem
        propKey="block"
        propSubKey="smWidth"
        type="select"
        options={smWidthOptions}
        label="Tablet Width"
      />
      <ToolbarItem
        propKey="block"
        propSubKey="xsWidth"
        type="select"
        options={xsWidthOptions}
        label="Mobile Width"
      />
      <ToolbarItem
        propKey="block"
        propSubKey="align"
        type="select"
        options={alignOptions}
        label="Align"
      />
      <ToolbarItem
        propKey="block"
        propSubKey="vAlign"
        type="select"
        options={vAlignOptions}
        label="Vertical align"
      />
    </ToolbarSection>
  )
}
