import React from 'react'
import Select from 'react-select'
import { FormError } from 'react-pagebuilder/pb-components/atoms'

const FormSelect = ({
  options = [],
  placeholder = '',
  name = '',
  value = '',
  className = '',
  readOnly = false,
  disabled = false,
  status = '',
  errorMessage = '',
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
}) => {
  const classNames = `
    pb-form-select 
    ${status ? ` pb-form-select--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  const localOnChange = selectedOption => {
    if (onChange)
      onChange({
        target: {
          value: selectedOption.value,
          name: name,
          type: 'select',
        },
      })
  }

  return (
    <div className={classNames}>
      <Select
        className="pb-form-select__input"
        classNamePrefix="pb-form-select__input"
        closeMenuOnSelect
        isClearable={false}
        isSearchable
        isDisabled={disabled}
        readOnly={readOnly}
        instanceId={name}
        name={name}
        placeholder={placeholder}
        onChange={localOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
        options={options}
        value={options?.find(option => option.value === value) || ''}
      />
      <FormError
        className="pb-form-input__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormSelect
