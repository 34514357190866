import React from 'react'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

export const RowSettings = () => {
  const noGuttersOptions = [
    { value: false, label: 'Yes' },
    { value: true, label: 'No' },
  ]

  return (
    <ToolbarSection title="Settings" initOpen>
      <ToolbarItem
        propKey="block"
        propSubKey="noGutters"
        type="select"
        options={noGuttersOptions}
        label="Gutters"
      />
    </ToolbarSection>
  )
}
