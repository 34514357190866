import React from 'react'
import { Close } from 'react-pagebuilder/pb-components/icons'

const Modal = ({ open, setOpen, children }) => {
  const close = e => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }

  return (
    <>
      {open && <div className="pb-overlay-modal" onClick={close}></div>}
      {open && (
        <div className={'pb-modal'}>
          <span className="pb-modal__close" onClick={close}>
            <Close />
          </span>
          <div className="pb-modal__body">{children}</div>
        </div>
      )}
    </>
  )
}

export default Modal
