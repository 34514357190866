import React from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'

export const alignOpts = [
  { label: 'Sinistra', value: 'left' },
  { label: 'Centro', value: 'center' },
  { label: 'Destra', value: 'right' },
]

export const ImageSettings = props => {
  const { customs } = useNode(node => ({
    customs: node.data.custom,
  }))

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="src"
          type="text"
          label="Src"
          description="Paste your url or upload a file"
          dynamic={dynamicProps.includes('src') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['src']}
        />
        <ToolbarItem propKey="block" propSubKey="src" type="file" />
        <ToolbarItem
          propKey="block"
          propSubKey="alt"
          type="text"
          label="Alt Text"
          dynamic={dynamicProps.includes('alt') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['alt']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="zoommabile"
          type="switch"
          label="Immagine zoommabile"
          dynamic={dynamicProps.includes('zoommabile') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['zoommabile']}
        />
      </ToolbarSection>
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="align"
          type="select"
          label="Alignment"
          options={alignOpts}
          dynamic={dynamicProps.includes('align') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['align']}
        />
      </ToolbarSection>
    </>
  )
}

export const ImageFloatingSettings = () => React.createElement(ImageSettings, { floating: true })
