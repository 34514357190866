import { useNode } from '@craftjs/core'
import React from 'react'

import { MenuItemSettings } from './MenuItemSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { slugify } from 'utils'

export const defaultProps = {
  label: 'Menu Item',
  anchorId: '',
}

export const dynamicProps = []

export const MenuItem = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div
        className={'block-menuitem ' + props.className}
        ref={ref => props.retRef(ref)}
        name={blockProps.label}
        id={blockProps.anchorId}
      >
        {children?.props?.children}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

MenuItem.craft = withCraft({
  name: 'MenuItem',
  defaultProps: defaultProps,
  settings: MenuItemSettings,
})
