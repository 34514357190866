import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { adminApiAxiosInstance } from 'react-pagebuilder/services/api'

export const GMapSettings = props => {
  const { customs } = useNode(node => ({
    customs: node.data.custom,
  }))

  return (
    <>
      <div className="empty-message">
        <p>No options available</p>
      </div>
      {/*
        <ToolbarSection title="Settings" initOpen>
          {!props.floating && (
            <ToolbarItem
              propKey="block"
              propSubKey="variant"
              type="select"
              label="Variant"
              options={typeOptions}
              dynamic={dynamicProps.includes('variant') && customs.dynamicData}
              dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
              defaultValue={defaultProps['variant']}
            />
          )}
          <ToolbarItem
            propKey="block"
            propSubKey="label"
            type="text"
            label="Label"
            dynamic={dynamicProps.includes('label') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['label']}
          />
        </ToolbarSection>
      */}
    </>
  )
}

export const GMapFloatingSettings = () => React.createElement(GMapSettings, { floating: true })
