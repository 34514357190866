import React, { useState, useContext } from 'react'
import { useEditor } from '@craftjs/core'
import { CustomBlocksContext } from '../../../../../services/customBlocksContext'
import { serializeNodeTree } from '../../../../../utils/node'

export const CreateCustomBlock = ({ selected, onChange }) => {
  const { query } = useEditor()

  const [name, setName] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessagee] = useState('')

  const { addCustomBlock } = useContext(CustomBlocksContext)

  const onCreateCustomBlock = () => {
    const nodes = query.node(selected).toNodeTree()
    const serializedNodes = serializeNodeTree(nodes, query)
    const json_content = JSON.stringify(serializedNodes)

    addCustomBlock(name, json_content)
      .then(data => {
        setErrorMessagee('')
        setSuccessMessage('Custom Block creato con successo')
      })
      .catch(err => {
        setSuccessMessage('')
        setErrorMessagee('Impossibile creare il Custom Block')
      })
  }

  return (
    <>
      <p>Name</p>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <input type="text" onChange={e => setName(e.target.value)} />
      <button onClick={() => onCreateCustomBlock()}>Create</button>
      <button onClick={() => onChange(false)}>Cancel</button>
    </>
  )
}
