import React from 'react'
import { Editor as CraftEditor, Frame, Element } from '@craftjs/core'
import {
  BibliographyBox,
  Button,
  CategoryBox,
  Col,
  ColorBox,
  CustomCode,
  Figure,
  FocusBox,
  GMap,
  Image,
  MenuItem,
  ObserveBox,
  PreviewEnd,
  ROOT,
  Row,
  Separator,
  Slider,
  StrumentoEmbedded,
  Tag,
  TargetBox,
  Text,
  TextEditor,
  Video,
} from '../components'

import { RenderNode } from './RenderNode'
import { Viewport } from './Viewport'
import { ConditionsWrapper } from 'react-pagebuilder/pagebuilder/ConditionsWrapper'

export const resolver = {
  BibliographyBox,
  Button,
  CategoryBox,
  Col,
  ColorBox,
  CustomCode,
  Figure,
  FocusBox,
  GMap,
  Image,
  MenuItem,
  ObserveBox,
  PreviewEnd,
  ROOT,
  Row,
  Separator,
  Slider,
  StrumentoEmbedded,
  Tag,
  TargetBox,
  Text,
  TextEditor,
  Video,
}

export const Editor = ({ json, editMode, withConditions }) => {
  const Wrapper = withConditions ? ConditionsWrapper : React.Fragment

  return (
    <>
      {editMode ? (
        <CraftEditor resolver={resolver} enabled={true} onRender={RenderNode}>
          <Viewport>
            <Frame data={json}>
              <Element is={ROOT} canvas></Element>
            </Frame>
          </Viewport>
        </CraftEditor>
      ) : (
        <CraftEditor resolver={resolver} enabled={false}>
          <Wrapper>
            <Frame data={json}></Frame>
          </Wrapper>
        </CraftEditor>
      )}
    </>
  )
}
