import { adminApiAxiosInstance } from 'react-pagebuilder/services/api'

export const AddGblinkPluginDialog = CKEDITOR => {
  CKEDITOR.dialog.add('gblinkDialog', function(editor) {
    return {
      title: 'Link interno',
      minWidth: 400,
      minHeight: 200,
      contents: [
        {
          id: 'tab-basic',
          label: 'Basic settings',
          elements: [
            {
              type: 'text',
              id: 'anchor_text',
              label: 'Anchor Text',
              validate: CKEDITOR.dialog.validate.notEmpty('Anchor Text field cannot be empty.'),
            },
            {
              type: 'select',
              id: 'content_id',
              label: 'Link to',
              validate: CKEDITOR.dialog.validate.notEmpty('Link to field cannot be empty.'),
              items: [],
              onLoad: function() {
                var localthis = this
                localthis.add('Loading...', '-')
                adminApiAxiosInstance.get('linkable_objects/?pagebuilder=true').then(res => {
                  const data = res.data
                  localthis.remove('-')
                  localthis.add('< Choose >', '-')
                  for (let i in data) {
                    for (let j in data[i].linkable_objects) {
                      localthis.add(
                        data[i].linkable_objects[j].alias,
                        data[i].content_type.id + '|' + data[i].linkable_objects[j].id
                      )
                    }
                  }
                  window.gblink_linkable_objects_loaded = true
                })
              },
            },
          ],
        },
        {
          id: 'tab-adv',
          label: 'Advanced Settings',
          elements: [
            {
              type: 'checkbox',
              id: 'new_window',
              label: 'Open in new Window',
            },
            {
              type: 'text',
              id: 'destination_anchor_id',
              label: 'Destination Anchor Id',
            },
          ],
        },
      ],
      onShow: function() {
        var dialog = this
        var node = editor.getSelection().getStartElement().$
        dialog.setValueOf('tab-basic', 'anchor_text', editor.getSelection().getSelectedText())
        if (node.getAttribute('data-gb-anchorid'))
          dialog.setValueOf(
            'tab-adv',
            'destination_anchor_id',
            node.getAttribute('data-gb-anchorid')
          )
        if (node.getAttribute('target') == '_blank') dialog.setValueOf('tab-adv', 'new_window', 1)
        if (node.getAttribute('data-gb-href'))
          gblink_wait_set_link(dialog, node.getAttribute('data-gb-href'))
      },
      onOk: function() {
        var dialog = this
        var gblink = editor.document.createElement('a')

        gblink.setText(dialog.getValueOf('tab-basic', 'anchor_text'))

        gblink.setAttribute('class', 'gblink')
        gblink.setAttribute('href', '#')
        gblink.setAttribute('data-gb-href', dialog.getValueOf('tab-basic', 'content_id'))

        if (dialog.getValueOf('tab-adv', 'destination_anchor_id'))
          gblink.setAttribute(
            'data-gb-anchorid',
            dialog.getValueOf('tab-adv', 'destination_anchor_id')
          )

        if (dialog.getValueOf('tab-adv', 'new_window')) gblink.setAttribute('target', '_blank')

        editor.insertElement(gblink)
      },
    }
  })
}

const gblink_wait_set_link = (dialog, value) => {
  setTimeout(function() {
    if (!window.gblink_linkable_objects_loaded) gblink_wait_set_link(dialog, value)
    else dialog.setValueOf('tab-basic', 'content_id', value)
  }, 500)
}
