import React, { useEffect, useState } from 'react'
import { PBTooltip } from 'react-pagebuilder/pb-components/atoms'

export const ToolbarSection = ({
  title,
  description,
  open,
  onChange,
  disabled = false,
  children,
  buttonTooltip,
  initOpen = false,
}) => {
  const [visible, setVisible] = useState(initOpen)

  const [state, setState] = useState()

  const changeState = state => {
    if (onChange) {
      onChange(state)
    } else {
      setVisible(state)
    }
  }

  useEffect(() => {
    if (open !== undefined) {
      setState(open)
    } else {
      setState(visible)
    }
  }, [open, visible])

  const buttonContent = (
    <div
      className={`toolbar-section__button ${disabled ? 'disabled' : ''}`}
      onClick={() => (disabled ? false : changeState(!state))}
    >
      {title}
    </div>
  )

  return (
    <div className="toolbar-section">
      {buttonTooltip && false ? (
        <PBTooltip {...buttonTooltip}>{buttonContent}</PBTooltip>
      ) : (
        buttonContent
      )}
      {state && (
        <div className="toolbar-section__content">
          {description && (
            <div>
              <h6>{description}</h6>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  )
}
