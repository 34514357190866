import { resolver } from 'react-pagebuilder/editor'
import { BibliographyBox } from 'react-pagebuilder/components'

export const canDrag = (currentNode, helpers, fun) => {
  if (currentNode.data.props.conditions.disabled) {
    return false
  }
  const parentNode = helpers(currentNode.data.parent).get()

  // Non posso spostare i nodi figli di un BIBLIOGRAPHYBOX
  if (parentNode.data.type === BibliographyBox) {
    return false
  }

  if (fun) {
    return fun(currentNode, helpers)
  } else {
    return true
  }
}

export const canDrop = (targetNode, helpers, fun) => {
  if (targetNode.data.props.conditions.disabled) {
    return false
  }

  if (fun) {
    return fun(targetNode, helpers)
  } else {
    return true
  }
}

export const canMoveIn = (incomingNodes, currentNode, helpers, fun) => {
  if (currentNode.data.props.conditions.disabled) {
    return false
  }

  if (fun) {
    return fun(incomingNodes, currentNode, helpers)
  } else {
    return true
  }
}

export const canMoveOut = (outgoingNodes, currentNode, helpers, fun) => {
  if (outgoingNodes.every((outgoingNode) => outgoingNode.data.props.conditions.disabled)) {
    return false
  }

  if (fun) {
    return fun(outgoingNodes, currentNode, helpers)
  } else {
    return true
  }
}

export const canDropBlock = (currentNode, targetNode) => {
  const Block = resolver[targetNode.data.name]

  if (Block) {
    return Block.craft.rules.canMoveIn([currentNode], targetNode)
  }

  return false
}

export const canMoveInOnlyCols = (incomingNodes, currentNode, helper) => {
  return incomingNodes.every((incomingNode) => incomingNode.data.name === 'Col')
}

export const canMoveInOnlyImages = (incomingNode, currentNode, helper) => {
  return incomingNode.data.name === 'Image'
}
