import React from 'react'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

export const MenuItemSettings = () => {
  return (
    <ToolbarSection title="Settings" initOpen>
      <ToolbarItem propKey="block" propSubKey="label" type="text" label="Label" />
      <ToolbarItem propKey="block" propSubKey="anchorId" type="text" label="ID ancora" />
    </ToolbarSection>
  )
}
