import React from 'react'
import { useNode } from '@craftjs/core'
import { TargetBoxSettings } from './TargetBoxSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { TargetBox as OriginalTargetBox } from 'react-components/molecules'

export const defaultProps = {
  color: 'default',
}

export const dynamicProps = []

export const TargetBox = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { color } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-wtarget-box ' + props.className}>
        <OriginalTargetBox color={color}>{children?.props?.children}</OriginalTargetBox>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

TargetBox.craft = withCraft({
  name: 'TargetBox',
  defaultProps: defaultProps,
  settings: TargetBoxSettings,
})
