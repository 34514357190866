import React, { useState, useEffect } from 'react'
import useWindowDimensions from 'react-pagebuilder/hooks/useWindowDimensions'
import { useUtente } from 'hooks'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

export const GlobalContext = React.createContext()

const GlobalContextProvider = ({
  contentTypeId,
  objectId,
  pkey,
  editMode: manualEditMode,
  draft: manualDraft,
  children,
}) => {
  const [showImport, setShowImport] = useState(false)
  const [previewMode, setPreviewMode] = useState(false)
  const [wireframeMode, setWireframeMode] = useState(true)
  const { width } = useWindowDimensions()
  const [windowMode, setWindowMode] = useState()
  const [sidebarTab, setSidebarTab] = useState()
  const [leftSidebarTab, setLeftSidebarTab] = useState()
  const [assetId, setAssetId] = useState()
  const [floatingSettingsOpen, setFloatingSettingsOpen] = useState()

  const { utente, fetching: loading } = useSelector(state => state.utente)
  const { edit: toolbarEdit } = useSelector(state => state.toolbar)
  const router = useRouter()

  const [editMode, setEditMode] = useState(undefined)
  const [draft, setDraft] = useState(undefined)

  const { locale: lang } = { locale: 'it' }

  useEffect(() => {
    if (!loading) {
      if (utente && utente.is_staff) {
        const edit = router.asPath.includes('?anteprima') ? false : toolbarEdit
        setEditMode(manualEditMode !== undefined && manualEditMode !== null ? manualEditMode : edit)
        const draft = router.asPath.includes('?anteprima') ? true : manualDraft
        setDraft(draft)
      } else {
        setEditMode(false)
      }
    }
  }, [loading, utente, toolbarEdit, manualEditMode, manualDraft])

  useEffect(() => {
    if (width <= 575) {
      setWindowMode('mobile')
    } else if (width <= 991) {
      setWindowMode('tablet')
    } else {
      setWindowMode('desktop')
    }
  }, [width])

  return (
    <GlobalContext.Provider
      value={{
        contentTypeId,
        objectId,
        pkey,
        lang,
        showImport,
        setShowImport,
        editMode,
        previewMode,
        setPreviewMode,
        utente,
        windowMode,
        wireframeMode,
        setWireframeMode,
        draft,
        sidebarTab,
        setSidebarTab,
        leftSidebarTab,
        setLeftSidebarTab,
        assetId,
        setAssetId,
        floatingSettingsOpen,
        setFloatingSettingsOpen,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
