import React from 'react'
import { useNode } from '@craftjs/core'
import { VideoSettings, VideoFloatingSettings } from './VideoSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { Video as OriginalVideo } from 'react-components/atoms'

export const defaultProps = {
  provider: 'youtube',
  videoId: '',
  autoplay: false,
  loop: false,
  controls: true,
  related: true,
  modest: false,
}

export const dynamicProps = []

export const Video = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { provider, videoId, autoplay, loop, controls, related, modest } = blockProps

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-wvideo ' + props.className}>
        <OriginalVideo
          provider={provider}
          videoId={videoId}
          autoplay={autoplay}
          loop={loop}
          controls={controls}
          related={related}
          modest={modest}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Video.craft = withCraft({
  name: 'Video',
  defaultProps: defaultProps,
  settings: VideoSettings,
  floatingSettings: VideoFloatingSettings,
})
