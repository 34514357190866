import { useNode } from '@craftjs/core'
import React from 'react'

import { ColSettings } from './ColSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {
  mdWidth: '',
  smWidth: '',
  xsWidth: '',
  align: '',
  vAlign: '',
  children: [],
}

export const dynamicProps = []

export const Col = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { mdWidth, smWidth, xsWidth, align, vAlign } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div
        className={`${xsWidth ? `col-${xsWidth}` : 'col'} ${smWidth ? `col-sm-${smWidth}` : ''} ${
          mdWidth ? `col-md-${mdWidth}` : ''
        } ${align ? `u-align-${align}` : ''} ${vAlign ? `u-col-valign-${vAlign}` : ''} ${
          props.className
        }`}
        ref={ref => props.retRef(ref)}
      >
        {children?.props?.children}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Col.craft = withCraft({
  name: 'Col',
  defaultProps: defaultProps,
  settings: ColSettings,
})
