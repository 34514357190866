import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { StrumentoEmbeddedSettings } from './StrumentoEmbeddedSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import StrumentoCard from 'components/card/StrumentoCard'
import { api } from 'utils'
import { STRUMENTI } from 'utils/endpoint'

export const defaultProps = {
  strumentoId: 0,
}

export const dynamicProps = []

export const StrumentoEmbedded = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { strumentoId } = blockProps

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const [strumento, setStrumento] = useState(null)

    useEffect(() => {
      if (strumentoId) initStrumento()
    }, [strumentoId])

    async function initStrumento() {
      const { data } = await api.get(`${STRUMENTI}${strumentoId}/`)
      setStrumento(data)
    }

    return (
      <div ref={ref => props.retRef(ref)} className={'block-strumento-embedded ' + props.className}>
        <div
          className={`block-strumento-embedded__content ${
            strumento ? '' : 'block-strumento-embedded__content--empty'
          }`}
        >
          {!!strumento && <StrumentoCard strumento={strumento} horizontal={true} isEmbedded />}
        </div>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

StrumentoEmbedded.craft = withCraft({
  name: 'StrumentoEmbedded',
  defaultProps: defaultProps,
  settings: StrumentoEmbeddedSettings,
})
