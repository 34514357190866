import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { adminApiAxiosInstance } from 'react-pagebuilder/services/api'

const providerOpts = [
  { label: 'Youtube', value: 'youtube' },
  { label: 'Vimeo', value: 'vimeo' },
]

export const VideoSettings = props => {
  const { customs } = useNode(node => {
    console.log(node.data)
    return {
      customs: node.data.custom,
    }
  })

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        {!props.floating && (
          <ToolbarItem
            propKey="block"
            propSubKey="provider"
            type="select"
            label="Provider"
            options={providerOpts}
            dynamic={dynamicProps.includes('provider') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['provider']}
          />
        )}
        <ToolbarItem
          propKey="block"
          propSubKey="videoId"
          type="text"
          label="Video ID"
          dynamic={dynamicProps.includes('videoId') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['videoId']}
        />
        {!props.floating && (
          <>
            <ToolbarItem
              propKey="block"
              propSubKey="autoplay"
              type="switch"
              label="Autoplay"
              dynamic={dynamicProps.includes('autoplay') && customs.dynamicData}
              dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
              defaultValue={defaultProps['autoplay']}
            />
            <ToolbarItem
              propKey="block"
              propSubKey="loop"
              type="switch"
              label="Loop"
              dynamic={dynamicProps.includes('loop') && customs.dynamicData}
              dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
              defaultValue={defaultProps['loop']}
            />
            <ToolbarItem
              propKey="block"
              propSubKey="controls"
              type="switch"
              label="Controls"
              dynamic={dynamicProps.includes('controls') && customs.dynamicData}
              dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
              defaultValue={defaultProps['controls']}
            />
            <ToolbarItem
              propKey="block"
              propSubKey="related"
              type="switch"
              label="Related"
              dynamic={dynamicProps.includes('related') && customs.dynamicData}
              dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
              defaultValue={defaultProps['related']}
            />
            <ToolbarItem
              propKey="block"
              propSubKey="modest"
              type="switch"
              label="Modest"
              dynamic={dynamicProps.includes('modest') && customs.dynamicData}
              dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
              defaultValue={defaultProps['modest']}
            />
          </>
        )}
      </ToolbarSection>
    </>
  )
}

export const VideoFloatingSettings = () => React.createElement(VideoSettings, { floating: true })
