import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'

const colorOpts = [
  { label: 'Beige', value: '' },
  { label: 'Bianco', value: 'white' },
]
const paddingOpts = [
  { label: 'Sì', value: '' },
  { label: 'No', value: 'no-padding' },
]

export const ColorBoxSettings = props => {
  const { customs } = useNode(node => ({
    customs: node.data.custom,
  }))

  return (
    <>
      <ToolbarSection title="Style" initOpen>
        {!props.floating && (
          <ToolbarItem
            propKey="block"
            propSubKey="color"
            type="select"
            label="Color"
            options={colorOpts}
            dynamic={dynamicProps.includes('color') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['color']}
          />
        )}
        <ToolbarItem
          propKey="block"
          propSubKey="padding"
          type="select"
          label="Padding"
          options={paddingOpts}
          dynamic={dynamicProps.includes('padding') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['padding']}
        />
      </ToolbarSection>
    </>
  )
}
