import React from 'react'
import { useNode } from '@craftjs/core'

import { RowSettings } from './RowSettings'

import { canMoveInOnlyCols } from '../../utils/rules'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {
  noGutters: false,
}

export const dynamicProps = []

export const Row = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { noGutters } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div
        className={`row ${noGutters ? 'no-pad' : ''} ${props.className}`}
        ref={ref => props.retRef(ref)}
      >
        {children?.props?.children}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Row.craft = withCraft({
  name: 'Row',
  defaultProps: defaultProps,
  settings: RowSettings,
  canMoveIn: canMoveInOnlyCols,
})
