import React from 'react'
import { useNode } from '@craftjs/core'
import { ObserveBoxSettings, ObserveBoxFloatingSettings } from './ObserveBoxSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { ObserveBox as OriginalObserveBox } from 'react-components/molecules'

export const defaultProps = {}

export const dynamicProps = []

export const ObserveBox = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { exampleProp } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-wobserve-box ' + props.className}>
        <OriginalObserveBox
          title={children?.props?.children[0]}
          text={children?.props?.children[1]}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

ObserveBox.craft = withCraft({
  name: 'ObserveBox',
  defaultProps: defaultProps,
  settings: ObserveBoxSettings,
})
