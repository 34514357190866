import React from 'react'
import { useNode } from '@craftjs/core'
import { PreviewEndSettings } from './PreviewEndSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {}

export const dynamicProps = []

export const PreviewEnd = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'fine-anteprima-box ' + props.className} />
    )
  },
  defaultProps,
  dynamicProps
)

PreviewEnd.craft = withCraft({
  name: 'PreviewEnd',
  defaultProps: defaultProps,
  settings: PreviewEndSettings,
})
