import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { adminApiAxiosInstance } from 'react-pagebuilder/services/api'

const colorOpts = [
  { label: 'Default', value: 'default' },
  { label: 'Bianco', value: 'white' },
]

export const TargetBoxSettings = props => {
  const { customs } = useNode(node => ({
    customs: node.data.custom,
  }))

  return (
    <>
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="color"
          type="select"
          label="Color"
          options={colorOpts}
          dynamic={dynamicProps.includes('color') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['color']}
        />
      </ToolbarSection>
    </>
  )
}
