import { useState } from 'react'
import { DynamicToolbarItem } from './DynamicToolbarItem'

import dynamic from 'next/dynamic'
const CodeEditor = dynamic(import('../../../../../plugins/CodeEditor'), { ssr: false })

export const CodeToolbarItem = ({
  propKey,
  propSubKey,
  value,
  setProp,
  onChange,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
}) => {
  return (
    <>
      {!onlyDynamic && (
        <CodeEditor
          value={value}
          onChange={value =>
            setProp(props => {
              props[propKey][propSubKey] = onChange ? onChange(value) : value
            }, 1000)
          }
        />
      )}
      {dynamic && (
        <DynamicToolbarItem
          propKey={propKey}
          propSubKey={propSubKey}
          value={value}
          values={dynamicValues}
          setProp={setProp}
          onChange={onChange}
          defaultValue={defaultValue}
        />
      )}
    </>
  )
}
