import { DynamicDataBlock } from 'react-pagebuilder/components/DynamicDataBlock'
import { ROOT_NODE } from '@craftjs/core'
import { Button, ObserveBox, Tag, Figure, BibliographyBox } from 'react-pagebuilder/components'

import { parseNodeTree } from './block'

export const isDisabled = (nodeId, query = null, helper = null) => {
  if (!nodeId) {
    return false
  }

  let node = null
  if (query) {
    node = query.node(nodeId).get()
  }
  if (helper) {
    node = helper(nodeId).get()
  }
  if (!node) {
    return false
  }

  if (node.data.props.conditions.disabled) {
    return true
  }

  return false
}

export const isDeletable = (nodeId, query) => {
  if (!nodeId) {
    return false
  }

  if (isDisabled(nodeId, query)) {
    return false
  }

  let isDefaultDeletable = false
  try {
    isDefaultDeletable = query.node(nodeId).isDeletable()
  } catch (err) {
    return isDefaultDeletable
  }

  if (isDefaultDeletable) {
    const parentNodeId = query.node(nodeId).get().data.parent
    const parentNode = query.node(parentNodeId).get()

    // Non si può eliminare il nodo (ROW) figlio di un DYNAMICDATABLOCK
    if (parentNode.data.type === DynamicDataBlock) {
      return false
    }
    // Non si può eliminare il nodo (TEXT) figlio di un BUTTON
    else if (parentNode.data.type === Button) {
      return false
    }
    // Non si può eliminare il nodo (TEXT) figlio di un TAG
    else if (parentNode.data.type === Tag) {
      return false
    }
    // Non si può eliminare il nodo (TEXT) figlio di un FIGURE
    else if (parentNode.data.type === Figure) {
      return false
    } else if (parentNode.data.type === BibliographyBox) {
      // Non si possono eliminare i primi due figli
      const index = parentNode.data.nodes.indexOf(nodeId)
      if (index <= 1) return false
    }
    // Non si può eliminare il nodo (TEXT) figlio di un OBSERVEBOX
    else if (parentNode.data.type === ObserveBox) {
      return false
    }
  }

  return isDefaultDeletable
}

export const isMoveable = (nodeId, query) => {
  if (!nodeId) {
    return false
  }

  if (isDisabled(nodeId, query)) {
    return false
  }

  const isDefaultMoveable = query.node(nodeId).isDraggable()

  if (isDefaultMoveable) {
    const parentNodeId = query.node(nodeId).get().data.parent
    const parentNode = query.node(parentNodeId).get()

    // Non si può spostare il nodo (ROW) figlio di un DYNAMICDATABLOCK
    if (parentNode.data.type === DynamicDataBlock) {
      return false
    }
    // Non si può spostate il nodo (TEXT) figlio di un BUTTON
    else if (parentNode.data.type === Button) {
      return false
    }
    // Non si può spostare il nodo (TEXT) figlio di un FIGURE
    else if (parentNode.data.type === Figure) {
      return false
    }
    // Non si può spostare il nodo figlio di un BIBLIOGRAPHYBOX
    else if (parentNode.data.type === BibliographyBox) {
      return false
    }
    // Non si può spostare il nodo (TEXT) figlio di un OBSERVEBOX
    else if (parentNode.data.type === ObserveBox) {
      return false
    }
  }

  return isDefaultMoveable
}

export const isClonable = (nodeId, query) => {
  if (!nodeId) {
    return false
  }

  if (isDisabled(nodeId, query)) {
    return false
  }

  const isDefaultClonable = nodeId !== ROOT_NODE

  if (isDefaultClonable) {
    const parentNodeId = query.node(nodeId).get().data.parent
    const parentNode = query.node(parentNodeId).get()

    // Non si può clonare il nodo (ROW) figlio di un DYNAMICDATABLOCK
    if (parentNode.data.type === DynamicDataBlock) {
      return false
    }
    // Non si può clonare il nodo (TEXT) figlio di un BUTTON
    else if (parentNode.data.type === Button) {
      return false
    }
    // Non si può clonare il nodo (TEXT) figlio di un FIGURE
    else if (parentNode.data.type === Figure) {
      return false
    } else if (parentNode.data.type === BibliographyBox) {
      // Non si possono clonare i primi due figli
      const index = parentNode.data.nodes.indexOf(nodeId)
      if (index <= 1) return false
    }
    // Non si può clonare il nodo (TEXT) figlio di un OBSERVEBOX
    else if (parentNode.data.type === ObserveBox) {
      return false
    }
  }

  return isDefaultClonable
}

export const isCopyable = (nodeId, query) => {
  if (!nodeId) {
    return false
  }

  if (isDisabled(nodeId, query)) {
    return false
  }

  const isDefaultClonable = nodeId !== ROOT_NODE

  return isDefaultClonable
}

export const isDroppable = (nodeId, query) => {
  if (!nodeId) {
    return false
  }

  if (isDisabled(nodeId, query)) {
    return false
  }

  const isDefaultDroppable = query.node(nodeId).isCanvas()

  return isDefaultDroppable
}

export const isParentSelectable = (nodeId) => {
  if (!nodeId) {
    return false
  }

  return nodeId !== ROOT_NODE
}

export const getAncestors = (nodeId, query, ancestors = [], level = 0) => {
  const node = query.node(nodeId).get()
  if (!node) {
    return ancestors
  }

  if (level !== 0) {
    ancestors.push(nodeId)
  }

  if (!node.data.parent) {
    return ancestors
  }

  ancestors = getAncestors(node.data.parent, query, ancestors, level + 1)

  return ancestors
}

export const serializeNodeTree = (nodeTree, query) => {
  const serializedNodeTree = {}
  serializedNodeTree['rootNodeId'] = nodeTree['rootNodeId']
  serializedNodeTree['nodes'] = {}
  Object.keys(nodeTree.nodes).forEach((nodeId) => {
    serializedNodeTree['nodes'][nodeId] = query.node(nodeId).toSerializedNode()
  })
  return serializedNodeTree
}

export const deserializeNodeTree = (serializedNodeTree, query) => {
  const nodeTree = {}
  nodeTree['rootNodeId'] = serializedNodeTree['rootNodeId']
  nodeTree['nodes'] = {}
  Object.keys(serializedNodeTree.nodes).forEach((nodeId) => {
    nodeTree['nodes'][nodeId] = query
      .parseSerializedNode(serializedNodeTree['nodes'][nodeId])
      .toNode()
  })
  return nodeTree
}

export const cloneNodeTree = (nodeId, query, disabled = null) => {
  const nodeTreeToClone = query.node(nodeId).toNodeTree()
  const serializedNodeTree = serializeNodeTree(nodeTreeToClone, query)
  const nodeTree = deserializeNodeTree(serializedNodeTree, query)
  const elements = parseNodeTree(nodeTree, nodeTree['rootNodeId'], 0, null, disabled)
  const clonedNodeTree = query.parseReactElement(elements).toNodeTree()
  return clonedNodeTree
}

export const addNodeTree = (nodeTree, targetId, actions, ignore = false, index = null) => {
  if (ignore) {
    actions.history.ignore().addNodeTree(nodeTree, targetId, index)
  } else {
    actions.addNodeTree(nodeTree, targetId, index)
  }
}

export const deleteNodeTree = (nodeId, actions, ignore = false) => {
  if (ignore) {
    actions.history.ignore().delete(nodeId)
  } else {
    actions.delete(nodeId)
  }
}

// @TODO Rimuovere
export const insertExtNodeOnParentFromVar = (
  nodes,
  actions,
  query,
  nodeId,
  parentId,
  indexToInsert
) => {
  const node = nodes.nodes[nodeId]
  // console.log({node});
  const freshNode = {
    data: {
      ...node.data,
      nodes: [],
      linkedNodes: {},
    },
  }

  // console.log({freshNode});
  const nodeToAdd = query.parseFreshNode(freshNode).toNode()
  // console.log({nodeToAdd});

  actions.add(nodeToAdd, parentId, indexToInsert)

  if (node.data.nodes.length !== 0) {
    node.data.nodes.forEach((childNodeId, index) => {
      console.log({ childNodeId })
      insertExtNodeOnParentFromVar(nodes, actions, query, childNodeId, nodeToAdd.id, index)
    })
  }
}
