import { Element } from '@craftjs/core'
import {
  BibliographyBox,
  Button,
  CategoryBox,
  Col,
  ColorBox,
  CustomCode,
  Figure,
  FocusBox,
  GMap,
  Image,
  MenuItem,
  ObserveBox,
  PreviewEnd,
  Row,
  Separator,
  Slider,
  StrumentoEmbedded,
  Tag,
  TargetBox,
  Text,
  TextEditor,
  Video,
} from 'react-pagebuilder/components'
import {
  Button as ButtonIcon,
  Image as ImageIcon,
  Bibliography as BibliographyIcon,
  Columns1 as Columns1Icon,
  Columns2 as Columns2Icon,
  Code as CodeIcon,
  Figure as FigureIcon,
  GMap as GMapIcon,
  MenuItem as MenuItemIcon,
  Observe as ObserveIcon,
  PreviewEnd as PreviewEndIcon,
  Separator as SeparatorIcon,
  Slider as SliderIcon,
  Tag as TagIcon,
  Target as TargetIcon,
  Text as TextIcon,
  Video as VideoIcon,
} from 'react-pagebuilder/pb-components/icons'

// Layout
export const ROW1COL = (
  <Element canvas is={Row}>
    <Element canvas is={Col}></Element>
  </Element>
)
export const ROW2COL = (
  <Element canvas is={Row}>
    <Element canvas is={Col}></Element>
    <Element canvas is={Col}></Element>
  </Element>
)
export const COL = <Element canvas is={Col}></Element>
export const SEPARATOR = <Element is={Separator}></Element>
export const PREVIEW_END = <Element is={PreviewEnd}></Element>
export const MENUITEM = <Element canvas is={MenuItem}></Element>

// Base

export const TEXT = (
  <Element is={Text}>
    <TextEditor
      block={{
        text: '<p>Hello world!</p>',
        className: 'text__content',
      }}
    />
  </Element>
)
export const BUTTON = (
  <Element is={Button}>
    <TextEditor
      block={{
        text: 'Click me\n',
        noHtml: true,
      }}
    />
  </Element>
)
export const TAG = (
  <Element is={Tag}>
    <TextEditor
      block={{
        text: '<p>Tag</p>',
      }}
    />
  </Element>
)

export const IMAGE = <Element is={Image}></Element>
export const SLIDER = <Element canvas is={Slider}></Element>
export const VIDEO = <Element is={Video}></Element>
export const GMAP = <Element is={GMap}></Element>

// Box
export const FIGURE = (
  <Element is={Figure}>
    <TextEditor
      block={{
        text: 'Didascalia della foto - immagine 878px x qualsiasi',
        noHtml: true,
      }}
    />
  </Element>
)
export const FOCUS_BOX = (
  <Element canvas is={FocusBox}>
    <Text>
      <TextEditor
        block={{
          text: '<p>Lorem ipsum...</p>',
        }}
      />
    </Text>
  </Element>
)
export const COLOR_BOX = <Element canvas is={ColorBox}></Element>
export const TARGET_BOX = (
  <Element canvas is={TargetBox}>
    <Text>
      <TextEditor
        block={{
          text: '<p class="icontitle icontitle--target">Titolo</p>' + '<p>Lorem ipsum...</p>',
        }}
      />
    </Text>
  </Element>
)
export const CATEGORY_BOX = (
  <Element canvas is={CategoryBox}>
    <Text>
      <TextEditor
        block={{
          text:
            '<p class="category">Categoria</p>' +
            '<p class="heading3">Titolo</p>' +
            '<p>Lorem ipsum...</p>',
        }}
      />
    </Text>
  </Element>
)
export const BIBLIOGRAPHY_BOX = (
  <Element canvas is={BibliographyBox}>
    <TextEditor
      block={{
        text: 'Per saperne di più\n',
        noHtml: true,
      }}
    />
    <TextEditor
      block={{
        text: '<p>Lorem ipsum...</p>',
      }}
    />
  </Element>
)
export const OBSERVE_BOX = (
  <Element is={ObserveBox}>
    <TextEditor
      block={{
        text: 'Valutiamo\n',
        noHtml: true,
      }}
    />
    <TextEditor
      block={{
        text: '<p>Lorem ipsum...</p>',
      }}
    />
  </Element>
)

// Embed
export const STRUMENTO_EMBEDDED = <StrumentoEmbedded />

// Extra
export const CUSTOM_CODE = <CustomCode />

// possibilità di inserire icona per il block
export const conf = [
  {
    title: 'Layout',
    blocks: {
      row_1col: {
        name: '1 Colonna',
        icon: <Columns1Icon />,
        block: ROW1COL,
      },
      row_2col: {
        name: '2 Colonne',
        icon: <Columns2Icon />,
        block: ROW2COL,
      },
      col: {
        name: 'Colonna',
        icon: <Columns1Icon />,
        block: COL,
      },
      separator: {
        name: 'Separator',
        icon: <SeparatorIcon />,
        block: SEPARATOR,
      },
      preview_end: {
        name: 'Fine anteprima',
        icon: <PreviewEndIcon />,
        block: PREVIEW_END,
      },
      menuitem: {
        name: 'Menu Item',
        icon: <MenuItemIcon />,
        block: MENUITEM,
      },
    },
  },
  {
    title: 'Base',
    blocks: {
      text: {
        name: 'Testo',
        icon: <TextIcon />,
        block: TEXT,
      },
      button: {
        name: 'Bottone',
        icon: <ButtonIcon />,
        block: BUTTON,
      },
      tag: {
        name: 'Tag',
        icon: <TagIcon />,
        block: TAG,
      },
      image: {
        name: 'Immagine',
        icon: <ImageIcon />,
        block: IMAGE,
      },
      slider: {
        name: 'Slider',
        icon: <SliderIcon />,
        block: SLIDER,
      },
      video_youtube: {
        name: 'Video',
        icon: <VideoIcon />,
        block: VIDEO,
      },
      /*
      google_map: {
        name: 'Map',
        icon: <GMapIcon />,
        block: GMAP,
      },
      */
    },
  },
  {
    title: 'Box',
    blocks: {
      figure: {
        name: 'Immagine con didascalia',
        icon: <FigureIcon />,
        block: FIGURE,
      },
      focus_box: {
        name: 'Box Focus',
        // icon: <FocusBoxIcon />,
        block: FOCUS_BOX,
      },
      color_box: {
        name: 'Box Colorato',
        // icon: <ColorBoxIcon />,
        block: COLOR_BOX,
      },
      target_box: {
        name: 'Box apertura lezione (target)',
        icon: <TargetIcon />,
        block: TARGET_BOX,
      },
      category_box: {
        name: 'Box Categoria',
        // icon: <CategoryBoxIcon />,
        block: CATEGORY_BOX,
      },
      bibliography_box: {
        name: 'Box Bibliografia',
        icon: <BibliographyIcon />,
        block: BIBLIOGRAPHY_BOX,
      },
      observe_box: {
        name: 'Box Osserviamo',
        icon: <ObserveIcon />,
        block: OBSERVE_BOX,
      },
    },
  },
  {
    title: 'Embed',
    blocks: {
      figure: {
        name: 'Strumento',
        // icon: <CodeIcon />,
        block: STRUMENTO_EMBEDDED,
      },
    },
  },
  {
    title: 'Extra',
    blocks: {
      figure: {
        name: 'Custom Code',
        icon: <CodeIcon />,
        block: CUSTOM_CODE,
      },
    },
  },
]
