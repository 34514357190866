import { DynamicToolbarItem } from './DynamicToolbarItem'
import { useState, useEffect } from 'react'

export const SliderToolbarItem = ({
  propKey,
  propSubKey,
  value,
  setProp,
  onChange,
  min,
  max,
  step,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
}) => {
  const [state, setState] = useState()

  useEffect(() => {
    setState(value)
  }, [value])

  return (
    <>
      {state !== undefined && (
        <>
          {!onlyDynamic && (
            <input
              type="range"
              min={min}
              max={max}
              step={step}
              value={state}
              onChange={e =>
                setProp(props => {
                  const value = e.target.value
                  props[propKey][propSubKey] = onChange ? onChange(value) : value
                }, 1000)
              }
            />
          )}
          {dynamic && (
            <DynamicToolbarItem
              propKey={propKey}
              propSubKey={propSubKey}
              value={state}
              values={dynamicValues}
              setProp={setProp}
              onChange={onChange}
              defaultValue={defaultValue}
            />
          )}
        </>
      )}
    </>
  )
}
