import React, { useState, useEffect } from 'react'

import dynamic from 'next/dynamic'

const ReactQuill = dynamic(() => import('react-quill'), {
  ssr: false,
  loading: () => <p>Loading ...</p>,
})
import 'react-quill/dist/quill.snow.css'

const modules = {
  clipboard: {
    matchVisual: true,
  },
  toolbar: {
    container: [
      [{ header: [false, 1] }],
      [{ align: [false, 'center', 'right', 'justify'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
      [{ background: ['#ffff00', '#ff00ff', '#00ffff'] }],
    ],
  },
}

/* const modules = {
    clipboard: {
        matchVisual: false
    },
    toolbar: {
      container: "#toolbar",
    },
  }; */

const formats = [
  'header',
  'align',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'code-block',
  'background',
]

const CustomToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-header" defaultValue="">
        <option value="1">Titolo</option>
        <option value="">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
    </span>
    <span className="ql-formats">
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
  </div>
)

export const RichTextEditor = ({ value, setValue, noHtml }) => {
  /**
   *  se noHtml (es.: in Text di Button) => uso value
   *  altrimenti (Text normale) => uso state
   */
  const [state, setState] = useState()

  useEffect(() => {
    if (!noHtml) {
      if (value.endsWith('<p><br></p>')) {
        const state = value
        setState(state.concat('<p><br></p>'))
      } else {
        setState(value)
      }
    }
  }, [])

  const onChange = (value, delta, source, editor) => {
    if (source === 'user') {
      if (!noHtml) {
        setState(noHtml ? editor.getText(value) : value)
      }
      setValue(noHtml ? editor.getText(value) : value)
    }
  }

  return (
    <ReactQuill
      className={`rte-fixed ${noHtml && 'rte-no-toolbar'}`}
      theme="snow"
      value={noHtml ? value : state}
      onChange={onChange}
      modules={modules}
      formats={formats}
      // readOnly={true}
    />
  )
}

import { CKEditor } from 'ckeditor4-react'
import { AddGblinkPlugin } from './ckeditor/gblink/plugin'
import { AddGbFileLinkPlugin } from './ckeditor/gbfilelink/plugin'

export const RichTextEditor3 = ({ name, value, setValue, noHtml, readOnly = false }) => {
  //const editorName = noHtml ? 'ckeditor-no-toolbar' : 'ckeditor' + '_' + name
  const editorName = 'ckeditor' + '_' + name

  const toggleToolbarVisibility = (value) => {
    const toolbarElement = document.getElementsByClassName('cke_editor_ckeditor_' + name)[0]
    if (toolbarElement) {
      if (value) {
        toolbarElement.classList.remove('ckeditor-hide')
        toolbarElement.classList.add('ckeditor-show')
      } else {
        toolbarElement.classList.add('ckeditor-hide')
        toolbarElement.classList.remove('ckeditor-show')
      }
    }
  }

  useEffect(() => {
    toggleToolbarVisibility(!readOnly)
  }, [readOnly])

  return (
    <CKEditor
      name={editorName}
      initData={value}
      type={'inline'}
      onChange={({ editor }) => {
        setValue(editor.getData())
      }}
      readOnly={readOnly}
      config={{
        extraPlugins: [
          'justify',
          'link',
          'tab',
          'copyformatting',
          'font',
          'sourcedialog',
          'image',
          'gblink',
          'gbfilelink',
        ],
        removePlugins: ['magicline', 'sourcearea'],
        toolbar: noHtml ? NO_HTML_TOOLBAR : TOOLBAR,
        removeButtons: '',
        removeDialogTabs: '',
        tabSpaces: 4,
        stylesSet: PAGEBUILDER_TEXT_STYLES,
        format_tags: 'p;h1;h2;h3;h4;h5;h6',
        extraAllowedContent: 'a[data-gb-href,data-gb-fileid]',
        versionCheck: false,
      }}
      onInstanceReady={({ editor }) => {
        toggleToolbarVisibility(!readOnly)
      }}
      onBeforeLoad={(CKEDITOR) => {
        AddGblinkPlugin(CKEDITOR)
        AddGbFileLinkPlugin(CKEDITOR)
      }}
    />
  )
}

const NO_HTML_TOOLBAR = [{ name: 'clipboard', items: ['Undo', 'Redo'] }]

const TOOLBAR = [
  { name: 'clipboard', items: ['Undo', 'Redo'] },
  { name: 'source', items: ['Source'] },
  {
    name: 'paragraph',
    items: [
      'NumberedList',
      'BulletedList',
      '-',
      'JustifyLeft',
      'JustifyCenter',
      'JustifyRight',
      'JustifyBlock',
      '-',
      'Blockquote',
      '-',
      'Outdent',
      'Indent',
    ],
  },
  {
    name: 'basicstyles',
    items: [
      'Bold',
      'Italic',
      'Underline',
      'Strike',
      'Subscript',
      'Superscript',
      '-',
      'CopyFormatting',
      'RemoveFormat',
    ],
  },
  { name: 'links', items: ['Gblink', 'Gbfilelink', 'Link', 'Unlink', 'Anchor'] },
  { name: 'styles', items: ['Styles', 'Format', 'FontSize'] },
  { name: 'other', items: ['Table', 'SpecialChar', 'FilerImage', 'Image'] },
]

const PAGEBUILDER_TEXT_STYLES = [
  { name: 'Normal', element: 'p', attributes: { class: '' } },
  { name: 'Large', element: 'p', attributes: { class: 'paragraph4' } },
  { name: 'Titolo 1', element: 'p', attributes: { class: 'heading1' } },
  { name: 'Titolo 2', element: 'p', attributes: { class: 'heading2' } },
  { name: 'Titolo 2 (font Lora)', element: 'p', attributes: { class: 'heading2-secondary' } },
  { name: 'Titolo 3', element: 'p', attributes: { class: 'heading3' } },
  {
    name: 'Titolo 3 (font Lora)',
    element: 'p',
    attributes: { class: 'heading3-secondary' },
  },
  { name: 'Titolo 4', element: 'p', attributes: { class: 'heading4' } },
  { name: 'Titolo 4 (font Lora)', element: 'p', attributes: { class: 'heading4-secondary' } },
  { name: 'Titolo 5', element: 'p', attributes: { class: 'heading5' } },
  { name: 'Categoria', element: 'p', attributes: { class: 'category' } },
  {
    name: 'Titolo Icona Target',
    element: 'p',
    attributes: { class: 'icontitle icontitle--target' },
  },
  {
    name: 'Titolo Icona Pencil',
    element: 'p',
    attributes: { class: 'icontitle icontitle--pencil' },
  },
  { name: 'Titolo Icona Paper', element: 'p', attributes: { class: 'icontitle icontitle--paper' } },
  { name: 'Titolo Icona Clock', element: 'p', attributes: { class: 'icontitle icontitle--clock' } },
  {
    name: 'Titolo Icona Headphones',
    element: 'p',
    attributes: { class: 'icontitle icontitle--headphones' },
  },
]
