import React from 'react'
import { useNode } from '@craftjs/core'
import { ColorBoxSettings } from './ColorBoxSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { ColorBox as OriginalColorBox } from 'react-components/molecules'

export const defaultProps = {
  color: '',
  padding: '',
}

export const dynamicProps = []

export const ColorBox = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { color, padding } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-wcolor-box ' + props.className}>
        <OriginalColorBox color={color} padding={padding}>
          {children?.props?.children}
        </OriginalColorBox>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

ColorBox.craft = withCraft({
  name: 'ColorBox',
  defaultProps: defaultProps,
  settings: ColorBoxSettings,
})
