import { useNode } from '@craftjs/core'
import React from 'react'

import { TagSettings } from './TagSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { Tag as OriginalTag } from 'react-components/atoms'

export const defaultProps = {}

export const dynamicProps = []

export const Tag = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div className={'block-tag ' + props.className} ref={ref => props.retRef(ref)}>
        <OriginalTag>{children?.props?.children[0]}</OriginalTag>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Tag.craft = withCraft({
  name: 'Tag',
  defaultProps: defaultProps,
  settings: TagSettings,
})
