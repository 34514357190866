import React from 'react'
import { useNode } from '@craftjs/core'
import { CategoryBoxSettings } from './CategoryBoxSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { CategoryBox as OriginalCategoryBox } from 'react-components/molecules'

export const defaultProps = {}

export const dynamicProps = []

export const CategoryBox = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-wcategory-box ' + props.className}>
        <OriginalCategoryBox>{children?.props?.children}</OriginalCategoryBox>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

CategoryBox.craft = withCraft({
  name: 'CategoryBox',
  defaultProps: defaultProps,
  settings: CategoryBoxSettings,
})
