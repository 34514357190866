import React from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { SliderSettings } from './SliderSettings'

import { canMoveInOnlyImages } from '../../utils/rules'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { Slider as OriginalSlider } from 'react-components/atoms'

export const defaultProps = {}

export const dynamicProps = []

export const Slider = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const { enabled } = useEditor(state => ({
      enabled: state.options.enabled,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-wslider ' + props.className}>
        <OriginalSlider editMode={enabled}>{children?.props?.children}</OriginalSlider>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Slider.craft = withCraft({
  name: 'Slider',
  defaultProps: defaultProps,
  settings: SliderSettings,
  canMoveIn: canMoveInOnlyImages,
})
