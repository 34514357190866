import { DynamicToolbarItem } from './DynamicToolbarItem'
import { useState, useEffect } from 'react'
import { RichTextEditor } from '../../../../../plugins/RichTextEditor'
import { FormInput } from 'react-pagebuilder/pb-components/atoms'

export const TextToolbarItem = ({
  propKey,
  propSubKey,
  value,
  setProp,
  onChange,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
  rte,
}) => {
  const [state, setState] = useState()

  useEffect(() => {
    setState(value)
  }, [])

  useEffect(() => {
    setState(value)
  }, [value])

  return (
    <>
      {state !== undefined && (
        <>
          {!onlyDynamic &&
            (rte ? (
              <RichTextEditor
                value={state}
                setValue={value => {
                  setState(value)
                  setProp(props => {
                    props[propKey][propSubKey] = onChange ? onChange(value) : value
                  }, 500)
                }}
                inline={true}
              />
            ) : (
              <FormInput
                value={state}
                onChange={e => {
                  setState(e.target.value)
                  setProp(props => {
                    const value = e.target.value
                    props[propKey][propSubKey] = onChange ? onChange(value) : value
                  }, 1000)
                }}
              />
            ))}
          {dynamic && (
            <DynamicToolbarItem
              propKey={propKey}
              propSubKey={propSubKey}
              value={state}
              values={dynamicValues}
              setProp={setProp}
              onChange={onChange}
              defaultValue={defaultValue}
            />
          )}
        </>
      )}
    </>
  )
}
