import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { getAllPaths } from '../../utils/dynamic'

export const TextEditorSettings = () => {
  const { customs } = useNode(node => ({
    customs: node.data.custom,
  }))

  return (
    <>
      {customs.dynamicData ? (
        <ToolbarSection title="Dynamic Data">
          <ToolbarItem
            propKey="block"
            propSubKey="texteditor"
            type="texteditor"
            label="Text Editor"
            onlyDynamic={true}
            dynamic={dynamicProps.includes('texteditor')}
            dynamicValues={getAllPaths(customs.dynamicData)}
            defaultValue={defaultProps['texteditor']}
          />
        </ToolbarSection>
      ) : (
        <div className="empty-message">
          <p>No options available</p>
        </div>
      )}
    </>
  )
}
