import React from 'react'
import { useNode } from '@craftjs/core'
import { GMapSettings, GMapFloatingSettings } from './GMapSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {}

export const dynamicProps = []

export const GMap = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { exampleProp } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-g-map ' + props.className}>
        {children?.props?.children}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

GMap.craft = withCraft({
  name: 'GMap',
  defaultProps: defaultProps,
  settings: GMapSettings,
  floatingSettings: GMapFloatingSettings,
})
