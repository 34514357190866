import React from 'react'
import { useEditor, useNode } from '@craftjs/core'
import { FigureSettings, FigureFloatingSettings } from './FigureSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { Figure as OriginalFigure } from 'react-components/molecules'

export const defaultProps = {
  src: 'https://via.placeholder.com/878x500',
  alt: 'Image',
  overflow: '',
  zoommabile: false,
}

export const dynamicProps = []

export const Figure = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { src, alt, overflow, zoommabile } = blockProps
    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const { enabled } = useEditor(state => ({
      enabled: state.options.enabled,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={`block-figure ${props.className}`}>
        <OriginalFigure
          overflow={overflow}
          src={src}
          alt={alt}
          title={alt}
          caption={children?.props?.children[0]}
          zoommabile={zoommabile && !enabled}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Figure.craft = withCraft({
  name: 'Figure',
  defaultProps: defaultProps,
  settings: FigureSettings,
  floatingSettings: FigureFloatingSettings,
})
