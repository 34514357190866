import React from 'react'
import { useEditor, useNode } from '@craftjs/core'
import { ImageSettings, ImageFloatingSettings } from './ImageSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { WImage } from 'react-components/atoms'

export const defaultProps = {
  src: 'https://via.placeholder.com/300x200',
  alt: 'Image',
  align: 'left',
  zoommabile: false,
}

export const dynamicProps = ['src']

export const Image = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { src, alt, align, zoommabile } = blockProps

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const { enabled } = useEditor(state => ({
      enabled: state.options.enabled,
    }))

    return (
      <div
        ref={ref => props.retRef(ref)}
        className={`block-image ${align ? `block-image--${align} ` : ''}${props.className}`}
      >
        <WImage
          src={_(src)}
          title={_(alt?.toAltTitle())}
          alt={alt?.toAltTitle()}
          pagebuilder
          zoommabile={zoommabile && !enabled}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Image.craft = withCraft({
  name: 'Image',
  defaultProps: defaultProps,
  settings: ImageSettings,
  floatingSettings: ImageFloatingSettings,
})
