import { AddGblinkPluginDialog } from './dialogs/gbfilelink'

export const AddGbFileLinkPlugin = CKEDITOR => {
  if (CKEDITOR.plugins.get('gbfilelink')) return
  CKEDITOR.plugins.add('gbfilelink', {
    init: function(editor) {
      AddGblinkPluginDialog(CKEDITOR)
      editor.addCommand('insertInternalFileLink', new CKEDITOR.dialogCommand('gbfilelinkDialog'))
      editor.ui.addButton('Gbfilelink', {
        label: 'Link to file',
        command: 'insertInternalFileLink',
        toolbar: 'links',
        icon: '/images/ckeditor/gbfilelink.png',
      })
      editor.on(
        'doubleclick',
        function(e) {
          var element = e.data.element
          if (element.is('a') && element.hasClass('gbfilelink')) e.data.dialog = 'gbfilelinkDialog'
        },
        null,
        null,
        100
      )
    },
  })
}
